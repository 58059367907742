import styled from '@emotion/styled';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { Media } from 'src/interface/media';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import CardFull from 'src/components/cards/card-full';
// import CardH from 'src/components/cards/card-h';
// import CardHalf from 'src/components/cards/card-half';
import Carousels from 'src/components/carousels';
// import WidgetBackline from 'src/components/widget/widget-backlink';
import { SHOW_COMMEMORATE } from 'src/constants';
import { PSection, TBlock } from 'src/interface/section';
import SectionEvent1 from 'src/section-events/events-014';
import { ITags } from 'src/interface/service';
import SectionLive from 'src/section-events/events-010/live';
import WidgetBackline from 'src/components/widget/widget-backlink';
import { timestamp } from 'src/utils/helper';
import { GET } from 'src/services';
import { convertDatasets } from 'src/utils/datasets';
// import SectionEvent1 from 'src/section-events/events-014';
import { controller } from 'src/switch-themes';
import DynamicAds, { KEY_ADS_POSITION } from 'src/utils/ads/dynamic-ads';
import useResponsive from 'src/utils/devices';
import SectionOneCard from '../section-card/section-one';
import SectionHeader from '../section-header';
import CardHalf from '../../cards/card-half';
import CardH from 'src/components/cards/card-h';
// import CardV from 'src/components/cards/card-v';
// import WidgetBackline from '../../widget/widget-backlink';

type Props = {
  ads?: any;
  highlight1: PSection['highlight1'];
  dataPopular: any;
  headerLatestNews: TBlock;
  eventDataLayer: IDataLayerGlobal;
  dataTags?: { data: ITags[] };
  soft?: any;
  dataSectionLive?: any;
  dataSectionPAOElection?: any;
};

const { description } = controller;

const SectionOne = ({ ads, highlight1, dataSectionLive, dataPopular, headerLatestNews, eventDataLayer, dataSectionPAOElection, soft }: Props) => {
  const responsive: Media = useResponsive();
  // const router = useRouter();
  const [dataNewsPAO, setDataNewsPAO] = useState<any[]>();
  const path = convertDatasets(dataSectionPAOElection[0], 'path', true);
  const limitLeft = convertDatasets(dataSectionPAOElection[0], 'limit-left', true);
  const limitRight = convertDatasets(dataSectionPAOElection[0], 'limit-right', true);
  const detectfront = responsive.isLaptop ? Number(limitLeft) : 1;
  const detectend = responsive.isLaptop ? Number(limitLeft) + Number(limitRight) : 5;
  useEffect(() => {
    (async () => {
      try {
        const uri: string = `/api/v1.0/categories${path}`;
        const data = await GET(uri, false, false);
        if (!_.isEmpty(data)) {
          setDataNewsPAO(data);
        }
      } catch (err: any) {
        console.error(`${timestamp()} ==========> EMPTY_ARTICLE ERROR : ${err}`);
      }
    })();
  }, []);

  return (
    <>
      {!_.isEmpty(dataSectionPAOElection) && <SectionWrapperPAO id='section-PAO' className='wrapper-1' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }} background={convertDatasets(dataSectionPAOElection[0], 'background-image', true)} background_mb={convertDatasets(dataSectionPAOElection[0], 'background-image-mobile', true)}>
        <SectionEvent1 dataSoft={soft} />
        {!_.isEmpty(dataNewsPAO) && (
          <div className='container background-image'>
            <SectionHeader data={' '} link={'/category' + path} fontColor={'#000'} />
            <div className='block-wrapper'>
              <div className='block-1'>
                {responsive.isMobile && (
                  <>
                    {_.map(dataNewsPAO?.slice(0, 1), (element, index) => (
                      <CardHalf
                        key={index}
                        data={element}
                        showFooter={false}
                        showBlurb={false}
                        showBadge={false}
                        color={'#fff'}
                        // ! DATALAYER
                        eventDataLayer={{
                          ...eventDataLayer,
                          position: 'ใต้:header',
                          section: 'section-PAO',
                          data: {
                            block: '1',
                            heading: highlight1?.title,
                            title: element?.title,
                            carousel: index + 1
                          }
                        }}
                      />
                    ))}

                  </>
                )}
                {responsive.isLaptop && (
                  <Carousels dots={true} mobileSlideShow={1} slideShow={1} showArrow={true}>
                    {_.map(dataNewsPAO?.slice(0, limitLeft < 1 ? 2 : limitLeft), (element, index) => (
                      <CardFull
                        key={index}
                        data={element}
                        showFooter={false}
                        showBlurb={false}
                        showBadge={false}
                        // ! DATALAYER
                        eventDataLayer={{
                          ...eventDataLayer,
                          position: 'ใต้:header',
                          section: 'section-PAO',
                          data: {
                            block: '1',
                            heading: highlight1?.title,
                            title: element?.title,
                            carousel: index + 1
                          }
                        }}
                      />
                    ))}
                  </Carousels>
                )}
              </div>
              <div className='block-2'>
                {_.map(dataNewsPAO?.slice(detectfront, detectend), (element, index) => (
                  <CardH
                    key={index}
                    data={element}
                    showFooter={true}
                    showBlurb={true}
                    showBadge={false}
                    // ! DATALAYER
                    eventDataLayer={{
                      ...eventDataLayer,
                      position: 'ใต้:header',
                      section: 'section-PAO',
                      data: {
                        block: '2',
                        heading: highlight1?.title,
                        title: element?.title,
                        carousel: index + 5
                      }
                    }}
                  />
                ))}
              </div>
            </div>

            <WidgetBackline link={'/category' + path} />
          </div>
        )}
      </SectionWrapperPAO>
      }


      <SectionWrapper id='section-1' className='container'>
        <SectionOneCard
          ads={ads}
          data={headerLatestNews}
          // ! DATALAYER
          eventDataLayer={{
            ...eventDataLayer,
            section: 'section-1',
            position: 'ใต้:header',
            data: {
              block: '1',
              heading: 'ข่าวล่าสุด'
            }
          }}
        />
        <div className='block-ads'>
          {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.STICKY_1} data={ads} />}
        </div>

      </SectionWrapper>
      <SectionWrapper id='section-2' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
        <h2 className='seo-index-h2'>{description}</h2>

        <div className={`grid-sec-one container desktop`} >
          <div className='block-1 --top'>
            <Carousels dots={true} mobileSlideShow={1} slideShow={1}>
              {_.map(highlight1?.data.slice(0, 5), (element, index) => (
                <CardFull
                  key={index}
                  data={element}
                  showFooter={false}
                  showBlurb={false}
                  showBadge={false}
                  // ! DATALAYER
                  eventDataLayer={{
                    ...eventDataLayer,
                    section: 'section-2',
                    position: 'ใต้:section-1',
                    data: {
                      block: '1',
                      heading: 'highlight',
                      title: element?.title,
                      carousel: index + 1
                    }
                  }}
                />
              ))}
            </Carousels>
          </div>
          {responsive.isTabletPro && (
            <div className='block-2'>
              {!_.isEmpty(dataPopular) && (
                <>
                  <SectionHeader data={dataPopular?.title} link={dataPopular?.link} fontColor={'#000'} />

                  <div className='card-list'>
                    {_.map(dataPopular?.data?.slice(0, 3), (element, index) => (
                      <div className='card-item' key={index}>
                        <CardFull
                          data={element}
                          showFooter={false}
                          showBlurb={false}
                          showBadge={false}
                          // ! DATALAYER
                          eventDataLayer={{
                            ...eventDataLayer,
                            section: 'section-2',
                            position: 'ใต้:section-1',
                            data: {
                              block: '2',
                              heading: 'ข่าวยอดนิยม',
                              title: element?.title,
                              index: index + 1
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
          {/* {responsive.isTabletMini && (
            <div className='block-2'>
              {!_.isEmpty(dataPopular) && (
                <>
                  <SectionHeader data={dataPopular?.title} link={dataPopular?.link} fontColor={'#000'} />
                  <div className='card-list'>
                    {_.map(dataPopular?.data?.slice(0, 3), (element, index) => (
                      <div className='card-item' key={index}>
                        <CardV
                          data={element}
                          showFooter={true}
                          showBlurb={false}
                          showBadge={false}
                          // ! DATALAYER
                          eventDataLayer={{
                            ...eventDataLayer,
                            data: {
                              block: '2',
                              heading: 'ข่าวยอดนิยม',
                              title: element?.title,
                              index: index + 1
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <WidgetBackline link={dataPopular?.link} />
                </>
              )}
            </div>
          )} */}
        </div>
      </SectionWrapper>

      {responsive.isTabletPro && (
        <SectionLive
          data={dataSectionLive}
        />
      )}
      {/* <SectionTagWrapper>
        <div className='container'>
          <SectionTags
            tags={dataTags}
            title='แท็กยอดนิยม'
            color='#194588'
            tagsIndex='index'
            // ! DATALAYER
            eventDataLayer={{
              ...eventDataLayer,
              section: 'แท็กยอดนิยม',
              position: 'ใต้:section-2',
              data: {
                heading: 'แท็กยอดนิยม',
                ...eventDataLayer?.data
              }
            }}
          />
        </div>
      </SectionTagWrapper> */}
    </>
  );
};

export default SectionOne;

interface IStyled {
  background?: any;
  background_mb?: any;
}

const SectionWrapper = styled.section`
  position: relative;
  overflow: hidden;
  padding: 30px 0 0;
  /* padding: 30px 0; */

  &#section-1 {
    max-width: 1280x;
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 0px;
    padding: 0;
        @media (max-width: 767px) {
    grid-template-columns: 1fr;

        }
    .block-ads {
      /* background-color: #ddd; */
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
  .heading-click-on-section {
    display: none;
    @media (max-width: 690px) {
      display: block;
    }
  }
  .heading-click {
    @media (max-width: 690px) {
      display: none;
    }
  }
  .seo-index-h2 {
    position: absolute;
    opacity: 0;
  }
  .grid-sec-one {
    display: flex;
    gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .block-1 {
      width: 75%;
      @media (max-width: 768px) {
        width: 100%;
      }
      &.--top {
        margin-top: 75px;
        .title {
          font-size: 34px; 
          @media (max-width: 690px) {
            font-size: 18px; 
          }
        }
        @media (max-width: 690px) {
          margin-top: 0px;

        }
      }
    }
    .block-2 {
      width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      .card-list {
        /* gap: 10px; */

        margin: -5px -5px;
        .card-item {
          padding: 5px 5px;
        }
      }
      @media (max-width: 768px) {
        width: 100%;
        .card-list {
          display: flex;
          flex-wrap: wrap;
          .card-item {
            padding: 5px 5px;
            width: 50%;
          }
        }
      }
    }
  }

`;

const SectionWrapperPAO = styled.section<IStyled>`
  position: relative;
  overflow: hidden;
  padding: 30px 0;

  .background-image {
    background-size: cover;
    padding: 15rem 30px 20px 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${(props) => props.background});
      .card-h-href .card-image{
         min-height: 103px;
          @media (max-width: 690px) {
            min-height: unset;
          }
      }
      .card-detail{
        padding: 5px 15px 0 25px;
          @media (max-width: 690px) {
            padding: 5px 15px 0 15px;
          }
      }
    @media (max-width: 820px) {
      padding: 30px 30px 20px 30px;
    }

    @media (max-width: 690px) {
      background-size: cover;
      padding: 9rem 15px 20px 15px;
      background-position: right;
      background-repeat: no-repeat;
      background-image: url(${(props) => props.background_mb});

      .heading-click-on-section li p {
        padding: 0.5rem 0px;
        color: white !important;
      }
    }
  }

  .card-h {
    background: white;
  }

  .slick-dots {
    .slick-active button:before {
      background: var(--secondary-color) !important;
      opacity: 1;
    }

    li button:before {
      content: '';
      background-color: white !important;
      opacity: 0.8;
      width: 32px;
      height: 9px;
    }
  }
    
  .heading-click-on-section {
    display: none;

    @media (max-width: 690px) {
      display: block;
    }
  }

  .heading-click {
    @media (max-width: 690px) {
      display: none;
    }
  }

  .heading .heading-click li p {
    padding: 0.75rem 0px;
    color: white !important;
    font-size: 18px;
  }

  &.wrapper-1 {
    .block-wrapper {
      display: flex;
      gap: 10px;

      @media (max-width: 690px) {
        margin-bottom: 10px;
      }
      .blurb {
        display: none;
      }
      @media (max-width: 960px) {
        flex-direction: column;
        .blurb {
          display: block;
        }
      }
      @media (max-width: 690px) {
        .blurb {
          display: none;
        }
      }
      .block-1 {
        margin-top: 10px;
        width: 65%;
        .title {
          font-size: 34px; 
          @media (max-width: 690px) {
            margin-top: 0px;
            font-size: 18px; 
          }
        }
        @media (max-width: 960px) {
          width: 100%;
        }
      }
      .block-2 {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 10px;
        width: 45%;
        max-height: 453.38px;
         justify-content: center;
        @media (max-width: 960px) {
          width: 100%;
        }
      }
    }
  }
  &.wrapper-3 {
    .block-wrapper {
      margin-bottom: 10px;
      .block-1 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        margin-top: 10px;
        @media (max-width: 768px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
      .block-2 {
        @media (max-width: 768px) {
          margin-top: 10px;
          display: grid;
          grid-gap: 10px;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
`;